.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #009fe3;
  color: #fff;
}
::selection {
  background: #009fe3;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(0, 159, 227, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
  overflow: hidden;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #444;
  font-family: "Karma", serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.3125rem + 0.5625
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.3125rem + 0.5625
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h1,
  .style_h1 {
    font-size: 1.875rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h2,
  .style_h2 {
    font-size: 1.5rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h3,
  .style_h3 {
    font-size: 1.125rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h4,
  .style_h4 {
    font-size: 1.125rem;
  }
}
h5 .style_h5 {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 .style_h5 {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h5 .style_h5 {
    font-size: 1.125rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #009fe3;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #444;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(0, 159, 227, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #444;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #009fe3;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #444;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(0, 159, 227, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.button {
  border-radius: 0.25rem;
  font-size: 1.125rem;
  padding: 0 1.5em;
  background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
  background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
  border: 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button .icon {
  fill: #fff;
  height: 1.5rem;
  margin: 0.5em 0;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button .text {
  font-weight: 700;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button .icon + .text {
  padding-left: 0.5em;
}
.button:focus {
  box-shadow: 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  outline: 0;
  background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
  background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
  border-color: #fff;
  color: #fff;
}
.button:focus .icon {
  fill: #fff;
}
.button:hover {
  background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
  background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
  border-color: #fff;
  color: #fff;
}
.button:hover .icon {
  fill: #fff;
}
.button:active,
.button.active {
  box-shadow: 0 0 0.1875rem #fff inset;
  background: -webkit-linear-gradient(top, rgba(0, 130, 185, 0.7) 100%, rgba(0, 155, 221, 0.7) 0%);
  background: linear-gradient(to bottom, rgba(0, 130, 185, 0.7) 100%, rgba(0, 155, 221, 0.7) 0%);
  border: 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button:active > span,
.button.active > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button:active .icon,
.button.active .icon {
  fill: #fff;
  height: 1.5rem;
  margin: 0.5em 0;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button:active .text,
.button.active .text {
  font-weight: 700;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button:active .icon + .text,
.button.active .icon + .text {
  padding-left: 0.5em;
}
.button .text {
  font-weight: 400;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #c3dcd4;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #c3dcd4;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #e3efeb;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #009fe3;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_circle.svg");
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #c3dcd4;
  margin: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #c3dcd4;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35em) {
  .defined_list dd {
    border-top: 0.0625rem solid #c3dcd4;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #444;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form_disclaimer {
  background: #e3efeb;
  border-radius: 0.25rem;
  color: #444;
  font-size: 85%;
  margin-bottom: 0.75em;
  padding: 1em 1.5em;
}
::-webkit-input-placeholder {
  color: #848484;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #848484;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #848484;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #009fe3;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #009fe3;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #444;
  font-family: "Karma", serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  .legend {
    font-size: 1.5rem;
  }
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #c3dcd4;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.1875rem #c3dcd4 inset;
  color: #444;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #009fe3;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.input:focus,
.textarea:focus {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.input {
  height: 2.75rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.file {
  display: -webkit-box;
  display: flex;
  flex-basis: 65%;
  height: 2.75rem;
  margin: 0 0 1em;
  position: relative;
}
.file.error .file_name {
  border-color: #009fe3;
}
.file .file_name {
  background: #fff;
  border: 0.0625rem solid #c3dcd4;
  border-radius: 0.25rem 0 0 0.25rem;
  box-shadow: 0 0 0.1875rem #c3dcd4 inset;
  color: #444;
  -webkit-box-flex: 0;
          flex: 0 1 100%;
  height: 2.75rem;
  line-height: calc(2.75rem - (0.0625rem * 2));
  overflow: hidden;
  padding: 0 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.125rem;
}
.file .file_button {
  border-radius: 0 0.25rem 0.25rem 0;
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  padding: 0 1.5em;
  z-index: 10;
  background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
  background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
  border: 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  margin: 0.5em 0;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file .file_button .text {
  font-weight: 700;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file .file_button .icon + .text {
  padding-left: 0.5em;
}
.file .file_button.focus {
  box-shadow: 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
  background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
  border-color: #fff;
  color: #fff;
}
.file .file_button.focus .icon {
  fill: #fff;
}
.file [type="file"] {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 2.75rem;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}
.file [type="file"]:hover ~ .file_button {
  background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
  background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
  border-color: #fff;
  color: #fff;
}
.file [type="file"]:hover ~ .file_button .icon {
  fill: #fff;
}
.file [type="file"]:active ~ .file_button {
  box-shadow: 0 0 0.1875rem #fff inset;
  background: -webkit-linear-gradient(top, rgba(0, 130, 185, 0.7) 100%, rgba(0, 155, 221, 0.7) 0%);
  background: linear-gradient(to bottom, rgba(0, 130, 185, 0.7) 100%, rgba(0, 155, 221, 0.7) 0%);
  border: 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file [type="file"]:active ~ .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(2.75rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file [type="file"]:active ~ .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  margin: 0.5em 0;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file [type="file"]:active ~ .file_button .text {
  font-weight: 700;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file [type="file"]:active ~ .file_button .icon + .text {
  padding-left: 0.5em;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #009fe3;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.control input:focus ~ .indicator {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #c3dcd4;
  border-radius: 0.25rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #444;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #c3dcd4;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.1875rem #c3dcd4 inset;
  color: #444;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #444;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #009fe3;
}
.select.error.focus {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.select.focus {
  border-color: #009fe3;
  box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
  color: #444;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #444;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #444;
}
.select select:focus::-ms-value {
  background: none;
  color: #444;
}
@media (min-width: 35em) {
  .formular .button_wrapper {
    text-align: right;
  }
}
button {
  cursor: pointer;
  height: 2.75rem;
  margin: 0.75em 0;
  outline: 0;
  overflow: hidden;
  padding: 0 1.5em;
  width: 100%;
}
@media (min-width: 35em) {
  button {
    width: auto;
  }
}
button::-moz-focus-inner {
  border: 0;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 159, 227, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 159, 227, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 159, 227, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 159, 227, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes moveGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media screen {
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 120rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
  }
  .header_top {
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(45, 45, 45, 0.75) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(45, 45, 45, 0.75) 100%);
    padding: 0.75em 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 450ms linear;
    transition: all 450ms linear;
    width: 100%;
    z-index: 1001;
  }
  .header_top .flex_container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .header_top .flex_container .company_logo {
    margin-bottom: 1em;
  }
  .header_top .header_links_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-left: auto;
  }
  .header_top.fixed {
    padding: 0.5em 0;
  }
  .header_top.fixed nav {
    height: 1.875rem;
  }
  .header_top.fixed .company_logo {
    width: 3.75rem;
  }
  .header_top.fixed .select_language {
    position: relative;
    top: 25%;
    z-index: 9999;
    /*@media (min-width: 330px) {
              right: (185 / @rem); //150
          }

          @media (min-width: @small_grid_breakpoint) {
              right: (190 / @rem); //150
          }

          @media (min-width: @medium_grid_breakpoint) {
              right: (340 / @rem); //330
          }

          @media (min-width: @large_grid_breakpoint) {
              right: (350 / @rem); //350
              //top: 41%;
          }

          @media (min-width: @xlarge_grid_breakpoint) {
              right: (365 / @rem); //350
              //top: 41%;
          }

          @media (min-width: @xxxlarge_grid_breakpoint) {
              right: (340 / @rem); //265
          }

          @media (min-width: 1600px) {
              right: (340 / @rem); //320
          }*/
  }
  .header_top.fixed .select_language:after {
    clear: both;
  }
  .header_top.fixed .select_language:after,
  .header_top.fixed .select_language:before {
    content: "";
    display: table;
  }
  .header_layout_select {
    margin-right: 0;
  }
  .header_layout_select ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .header_layout_select li {
    height: 2.125rem;
    list-style: none;
    margin: 0;
    margin-right: 0.5em;
    width: 2.125rem;
    cursor: pointer;
  }
  .header_layout_select li:hover div .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .header_layout_select li:hover.south .icon {
    background: #800032;
  }
  .header_layout_select li:hover.north .icon {
    background: #009fe3;
  }
  .header_layout_select li.north .icon {
    padding: 0.375rem;
  }
  .header_layout_select li div {
    display: block;
    width: 2.125rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-decoration: none;
  }
  .header_layout_select li div .icon {
    border: 0.125rem solid #fff;
    border-radius: 50%;
    display: block;
    fill: #fff;
    height: 2.125rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.125rem;
  }
  .header_layout_select li div .text {
    color: #fff;
    font-size: 0.76086957rem;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
  }
  .header_bottom {
    position: relative;
  }
  .header_bottom .seo_start_title {
    background: rgba(255, 255, 255, 0.5);
    bottom: 35%;
    padding: 1em;
    position: absolute;
    z-index: 20;
  }
  .header_bottom .seo_start_title h1 {
    color: #444;
    line-height: 1.2;
    margin-bottom: 0;
  }
  .header_bottom .seo_start_title h1 .small {
    display: none;
    font-size: 70%;
    font-weight: 400;
  }
  .header_bottom .logo_balken {
    background: #fff;
    border-bottom: 0.4375rem solid #009fe3;
    padding: 0.75em 0;
    width: 100%;
    z-index: 20;
  }
  .header_bottom .logo_balken .row {
    -webkit-box-pack: justify;
            justify-content: space-between;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .header_bottom .logo_balken .row div {
    margin: 0;
  }
  .header_bottom .logo_balken .logo_1,
  .header_bottom .logo_balken .logo_2,
  .header_bottom .logo_balken .logo_3,
  .header_bottom .logo_balken .logo_4 {
    display: block;
  }
  .sitetitle {
    bottom: 0;
    padding: 0.75em 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .sitetitle:before {
    background: rgba(255, 255, 255, 0.65);
    content: "";
    height: 100%;
    left: -50%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  .company_logo {
    display: block;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 4.6875rem;
  }
  .company_logo:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .startpage .author_ver {
    display: none;
  }
  .startpage .author_hor {
    bottom: -0.9375rem;
    display: inline-block;
    fill: #444;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.3125rem;
    width: 4.6875rem;
    z-index: 100;
  }
  .author_ver {
    bottom: -5.625rem;
    display: inline-block;
    fill: #cccccc;
    height: 4.6875rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.4375rem;
    width: 0.5625rem;
    z-index: 100;
  }
  .author_hor {
    display: none;
  }
  .header_picture {
    position: relative;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
  body {
    overflow-x: hidden;
  }
  main {
    display: block;
    position: relative;
  }
  main .datenerfassung .button a {
    text-decoration: none;
    color: #fff;
  }
  .no_padding {
    padding: 0 !important;
  }
  .startpage .main_wrapper_top,
  .startpage .main_wrapper_bottom {
    position: relative;
  }
  .startpage .main_wrapper_top .bogen,
  .startpage .main_wrapper_bottom .bogen {
    display: none;
  }
  .startpage .main_wrapper_top .bogen.bogen_top,
  .startpage .main_wrapper_bottom .bogen.bogen_top,
  .startpage .main_wrapper_top .bogen.bogen_bottom,
  .startpage .main_wrapper_bottom .bogen.bogen_bottom {
    background-repeat: no-repeat;
    background-size: cover;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    opacity: 0.8;
    right: 50%;
  }
  .startpage .main_wrapper_top .bogen.bogen_top,
  .startpage .main_wrapper_bottom .bogen.bogen_top {
    background-image: url("../images/bogen_startseite_top.svg");
    background-position: top right;
  }
  .startpage .main_wrapper_top .bogen.bogen_bottom,
  .startpage .main_wrapper_bottom .bogen.bogen_bottom {
    background-image: url("../images/bogen_startseite_bottom.svg");
    background-position: top left;
  }
  main .main_content {
    position: relative;
    z-index: 500;
  }
  main .bogen_unterseite {
    display: none;
  }
  .cooperation_overview .figure {
    background-color: #fff;
    dipslay: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    padding: 1.5em;
  }
  .cooperation_overview .figure img {
    max-height: 5.9375rem;
    width: auto;
  }
  .cooperation_overview .figure figcaption {
    margin-top: 1.2em;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0.75em 0 3em;
  }
  .breadcrumbs p {
    display: none;
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    color: #009fe3;
    margin: 0 0.375em;
  }
  .arrow_button {
    background: #009fe3;
    border-radius: 0.5em 0 0 0.5em;
    box-shadow: 0.1875rem 0.1875rem 0.125rem 0 rgba(26, 60, 75, 0.3);
    color: #fff;
    display: inline-block;
    margin-top: 1.5em;
    padding: 0.83333333em 0.75em 0.75em;
    position: relative;
    text-decoration: none;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arrow_button:hover {
    background: #154356;
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }
  .arrow_button:hover .arrow {
    fill: #154356;
  }
  .blue_background .arrow_button,
  .arrow_button.light {
    background: #e3efeb;
    color: #444;
    font-weight: 700;
  }
  .blue_background .arrow_button:hover,
  .arrow_button.light:hover {
    background: #13c6b7;
    color: #fff;
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
  }
  .blue_background .arrow_button:hover .arrow,
  .arrow_button.light:hover .arrow {
    fill: #13c6b7;
  }
  .blue_background .arrow_button .arrow,
  .arrow_button.light .arrow {
    fill: #e3efeb;
  }
  .arrow_button .text {
    margin-right: 1.5em;
  }
  .arrow_button .arrow {
    bottom: 0;
    display: block;
    fill: #009fe3;
    left: calc((1.5em / 2) - 0.1%);
    position: absolute;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 100%;
  }
  .arrow_button .arrow svg {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .announcement {
    padding: 2.25em 1.5em;
  }
  .links_overview {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 2.25em 1.5em;
    position: relative;
    z-index: 1;
  }
  .links_overview:after {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    content: "";
    height: 100%;
    left: -10%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  .links_overview .title {
    color: #fff;
  }
  .links_overview ul {
    list-style: url("../images/list_style_image_circle_light.svg");
    position: relative;
    z-index: 1000;
  }
  .links_overview a {
    border-bottom: 0.0625rem solid #fff;
    color: #fff;
    text-decoration: none;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .links_overview a:hover {
    border-bottom: 0;
  }
  .leistungen_overview {
    padding: 2.25em 1.5em 2.25em 0;
  }
  .leistungen_overview h2 {
    display: none;
    padding: 0;
  }
  .startpage .newsletter_teaser {
    position: relative;
    z-index: 100;
  }
  .startpage .newsletter_teaser .content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 4.5em 2.25em;
    position: absolute;
    z-index: 1;
  }
  .startpage .newsletter_teaser .content h2 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 1.5em;
  }
  .startpage .newsletter_teaser .newsletter_teaser_picture {
    background-size: cover;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    min-height: 21.875rem;
    position: relative;
    right: 50%;
    width: 100vw;
  }
  .startpage .newsletter_teaser .arrow_button {
    max-width: 25rem;
  }
  .startpage .startseite_teaser {
    position: relative;
    z-index: 100;
  }
  .startpage .startseite_teaser .content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 4.5em 2.25em;
    position: absolute;
    z-index: 1;
    color: #fff;
  }
  .startpage .startseite_teaser .content h2 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 1.5em;
  }
  .startpage .startseite_teaser .startseite_teaser_picture {
    background-size: cover;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    min-height: 46.875rem;
    position: relative;
    right: 50%;
    width: 100vw;
  }
  .startpage .startseite_teaser .arrow_button {
    max-width: 25rem;
  }
  .text_center {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 4.5em 0;
    text-align: center;
  }
  .text_center p {
    letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    width: 80%;
  }
  .text_center strong {
    color: #009fe3;
  }
  .contact_wrapper {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 1em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact span,
  .contact strong {
    min-width: 5rem;
  }
  .address_vcard_button {
    margin: 0 0 0.75em 0;
  }
  .address_call_button {
    margin: 0 0 0.75em 0;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: inline-block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.6em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_tax_aut {
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .infolist_overview .entry,
  .download_overview .entry,
  .useful_link_overview .entry,
  .overview .entry,
  .u_online_overview .entry,
  .infolist_overview .touch_link,
  .download_overview .touch_link,
  .useful_link_overview .touch_link,
  .overview .touch_link,
  .u_online_overview .touch_link {
    box-shadow: 0 0 0.0625rem transparent;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1.5em;
    padding: 0.75em 1em;
    position: relative;
    -webkit-transform: perspective(0.0625rem) translateZ(0);
            transform: perspective(0.0625rem) translateZ(0);
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
    -webkit-transition-property: color;
    transition-property: color;
    vertical-align: middle;
    z-index: 1;
  }
  .infolist_overview .entry:after,
  .download_overview .entry:after,
  .useful_link_overview .entry:after,
  .overview .entry:after,
  .u_online_overview .entry:after,
  .infolist_overview .touch_link:after,
  .download_overview .touch_link:after,
  .useful_link_overview .touch_link:after,
  .overview .touch_link:after,
  .u_online_overview .touch_link:after {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 0.3125rem;
    z-index: -1;
  }
  .infolist_overview .entry:before,
  .download_overview .entry:before,
  .useful_link_overview .entry:before,
  .overview .entry:before,
  .u_online_overview .entry:before,
  .infolist_overview .touch_link:before,
  .download_overview .touch_link:before,
  .useful_link_overview .touch_link:before,
  .overview .touch_link:before,
  .u_online_overview .touch_link:before {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    z-index: -1;
  }
  .infolist_overview .entry:hover,
  .download_overview .entry:hover,
  .useful_link_overview .entry:hover,
  .overview .entry:hover,
  .u_online_overview .entry:hover,
  .infolist_overview .touch_link:hover,
  .download_overview .touch_link:hover,
  .useful_link_overview .touch_link:hover,
  .overview .touch_link:hover,
  .u_online_overview .touch_link:hover {
    color: #fff;
  }
  .infolist_overview .entry:hover h3,
  .download_overview .entry:hover h3,
  .useful_link_overview .entry:hover h3,
  .overview .entry:hover h3,
  .u_online_overview .entry:hover h3,
  .infolist_overview .touch_link:hover h3,
  .download_overview .touch_link:hover h3,
  .useful_link_overview .touch_link:hover h3,
  .overview .touch_link:hover h3,
  .u_online_overview .touch_link:hover h3,
  .infolist_overview .entry:hover span,
  .download_overview .entry:hover span,
  .useful_link_overview .entry:hover span,
  .overview .entry:hover span,
  .u_online_overview .entry:hover span,
  .infolist_overview .touch_link:hover span,
  .download_overview .touch_link:hover span,
  .useful_link_overview .touch_link:hover span,
  .overview .touch_link:hover span,
  .u_online_overview .touch_link:hover span,
  .infolist_overview .entry:hover h2,
  .download_overview .entry:hover h2,
  .useful_link_overview .entry:hover h2,
  .overview .entry:hover h2,
  .u_online_overview .entry:hover h2,
  .infolist_overview .touch_link:hover h2,
  .download_overview .touch_link:hover h2,
  .useful_link_overview .touch_link:hover h2,
  .overview .touch_link:hover h2,
  .u_online_overview .touch_link:hover h2 {
    color: #fff;
  }
  .infolist_overview .entry:hover:before,
  .download_overview .entry:hover:before,
  .useful_link_overview .entry:hover:before,
  .overview .entry:hover:before,
  .u_online_overview .entry:hover:before,
  .infolist_overview .touch_link:hover:before,
  .download_overview .touch_link:hover:before,
  .useful_link_overview .touch_link:hover:before,
  .overview .touch_link:hover:before,
  .u_online_overview .touch_link:hover:before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .infolist_overview .entry p,
  .download_overview .entry p,
  .useful_link_overview .entry p,
  .overview .entry p,
  .u_online_overview .entry p,
  .infolist_overview .touch_link p,
  .download_overview .touch_link p,
  .useful_link_overview .touch_link p,
  .overview .touch_link p,
  .u_online_overview .touch_link p {
    margin: 0;
  }
  .right_align {
    float: right;
  }
  .bester_stb {
    bottom: 18%;
    position: absolute;
    right: 10%;
    z-index: 999;
  }
  .bester_stb img {
    max-width: 7rem;
  }
  .bester_stb_2020 {
    bottom: 45%;
    position: absolute;
    left: 4%;
    z-index: 999;
  }
  .bester_stb_2020 img {
    max-width: 8rem;
  }
  .select_language {
    position: relative;
    -webkit-transition: 300ms;
    transition: 300ms;
    z-index: 9999;
    /*@media (min-width: 330px) {
        right: (185 / @rem); //145
    }

    @media (min-width: @small_grid_breakpoint) {
        right: (190 / @rem); //150
    }

    @media (min-width: @medium_grid_breakpoint) {
        right: (340 / @rem); //320
    }

    @media (min-width: @large_grid_breakpoint) {
        right: (350 / @rem); //350
        //top: 41%;
    }
    
    @media (min-width: @xlarge_grid_breakpoint) {
        right: (365 / @rem); //350
        //top: 41%;
    }

    @media (min-width: @xxxlarge_grid_breakpoint) {
        right: (340 / @rem); //265
    }

    @media (min-width: 1600px) {
        right: (340 / @rem); //320
    }*/
  }
  .select_language:after {
    clear: both;
  }
  .select_language:after,
  .select_language:before {
    content: "";
    display: table;
  }
  .select_language ul {
    list-style: none !important;
  }
  .select_language li {
    float: left;
    margin-left: 0.5rem;
  }
  .select_language li:nth-child(1) a:before {
    background-image: url("../images/flags/de.svg");
  }
  .select_language li:nth-child(2) a:before {
    background-image: url("../images/flags/gb.svg");
  }
  .select_language li:nth-child(3) a:before {
    background-image: url("../images/flags/it.svg");
  }
  .select_language a {
    background: #009fe3;
    border-radius: 3rem;
    color: #fff;
    display: block;
    font-size: 1rem;
    height: 2.1rem;
    line-height: 2rem;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.1rem;
  }
  .select_language a:hover {
    background: transparent;
    box-shadow: 0 0 0.75rem #fff;
  }
  .select_language a:hover:before {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .select_language a:before {
    background-size: cover;
    border-radius: .875rem;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: visible;
    z-index: 2;
  }
  .select_language a span {
    position: relative;
  }
  .select_language a.active {
    background: transparent;
    box-shadow: 0 0 0.75rem #fff;
  }
  .select_language a.active:before {
    opacity: 1;
    visibility: visible;
  }
  .provenexpert {
    padding: 2rem 0;
    text-align: center;
  }
  .aside {
    display: none;
    position: absolute;
    top: 7.1875rem;
    z-index: 1000;
  }
  .aside .newsletter_teaser,
  .aside .teaser_overview {
    margin-bottom: 3em;
    padding: 1.5em;
  }
  .aside .newsletter_teaser h2,
  .aside .teaser_overview h2 {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    font-weight: 400;
  }
  .aside .newsletter_teaser {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
  }
  .aside .newsletter_teaser h2 {
    color: #fff;
  }
  .aside .teaser_overview {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
  }
  .aside .teaser_overview strong {
    color: #009fe3;
  }
  .aside .arrow_button {
    margin-bottom: 1.5em;
    margin-left: -55%;
    width: 140%;
  }
  .aside .arrow_button .text {
    margin-right: 0;
  }
  .aside .sidebar {
    margin-left: auto;
    width: 80%;
  }
  .sub_menu {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-bottom: 3em;
    margin-left: auto;
    padding: 1.5em;
    position: relative;
    z-index: 1;
  }
  .sub_menu:after {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
  .sub_menu .active_menu {
    margin-bottom: 1em;
  }
  .sub_menu .active_menu .small_circle {
    background: #444;
    border-radius: 50%;
    display: block;
    height: 0.375rem;
    margin: 0.5em 1em;
    position: relative;
    width: 0.375rem;
  }
  .sub_menu .active_menu > span:first-child {
    min-width: 5rem;
    display: inline-block;
  }
  .sub_menu li {
    border-bottom: 0.0625rem solid #009fe3;
    padding: 0.25em 0;
    position: relative;
    z-index: 1;
  }
  .sub_menu li:after {
    background: #009fe3;
    content: "";
    height: 0.0625rem;
    left: 100%;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1;
  }
  .sub_menu li:last-child {
    border-bottom: inherit;
  }
  .sub_menu li:last-child:after {
    display: none;
  }
  .sub_menu a {
    color: #444;
    text-decoration: none;
  }
  .sub_menu a.active {
    font-weight: 700;
  }
  .sub_menu a:hover {
    color: #009fe3;
  }
  footer .grey_background,
  footer .blue_background,
  footer .red_background {
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding: 1.5em 0 1em;
  }
  footer .grey_background {
    background: #e3efeb;
    position: relative;
    z-index: 1;
  }
  footer .grey_background.grey_background_element_2 {
    padding-top: 0 !important;
  }
  footer .grey_background .company_name {
    margin-bottom: 0;
  }
  footer .grey_background h2 {
    padding-top: 0;
  }
  footer .grey_background:before {
    background: #e3efeb;
    content: "";
    height: 100%;
    position: absolute;
    right: -10%;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  footer .grey_background a {
    color: #444;
  }
  footer .blue_background {
    background: #009fe3;
    color: #fff;
    position: relative;
    z-index: 1;
  }
  footer .blue_background:after {
    background: #009fe3;
    content: "";
    height: 100%;
    left: -10%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  footer .blue_background h2,
  footer .blue_background a {
    color: #fff;
  }
  footer .blue_background .addition {
    display: none;
  }
  footer .red_background {
    background: #800032;
    color: #fff;
    position: relative;
    z-index: 1;
  }
  footer .red_background:after {
    background: #800032;
    content: "";
    height: 100%;
    left: -10%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  footer .red_background h2,
  footer .red_background a {
    color: #fff;
  }
  footer .red_background .addition {
    display: none;
  }
  footer .type {
    margin-right: 0.375em;
  }
  footer h2 {
    margin-bottom: 1em;
  }
  footer .lower_part {
    position: relative;
  }
  footer .lower_part .text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    max-width: 120rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
    position: absolute;
    z-index: 1;
  }
  footer .lower_part a {
    color: white;
    text-decoration: none;
  }
  footer .lower_part a:hover {
    text-decoration: underline;
  }
  footer .footer_picture {
    background-size: cover;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    min-height: 14.6875rem;
    position: relative;
    right: 50%;
    width: 100vw;
  }
  .link_entries {
    background: rgba(54, 92, 109, 0.65);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0.9375rem;
    margin-top: 1.5em;
    padding: 1.5em;
    position: absolute;
    top: 0;
    z-index: 50;
  }
  .link_entries .link_entry {
    display: block;
    text-shadow: 0 0 0.0625rem #000000;
  }
  body,
  html {
    height: 100%;
    position: relative;
  }
  #ie10nomore {
    background: #ffffff;
    clear: both;
    display: none;
    height: 100%;
    left: 0;
    line-height: 21px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
  #ie10nomore .box {
    height: 250px;
    left: 50%;
    margin: -125px 0 0 -275px;
    position: relative;
    top: 50%;
    width: 550px;
  }
  #ie10nomore .browser {
    float: left;
    margin-top: 17px;
    width: 75px;
  }
  #ie10nomore h2 {
    color: #000000;
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 14px;
    padding: 0;
  }
  #ie10nomore p {
    color: #000000;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 14px;
  }
  #ie10nomore a {
    color: #000000;
    text-decoration: underline;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #009fe3;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #009fe3;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    background: #e3efeb;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 115%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0.75em;
  }
  [role="tab"]:hover,
  [role="tab"]:focus {
    background: #009fe3;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  [role="tab"]:hover .title,
  [role="tab"]:focus .title {
    color: #fff;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  [role="tab"]:hover .icon,
  [role="tab"]:focus .icon {
    fill: #fff;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  [role="tab"] .title {
    flex-basis: 130%;
    font-size: 90%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: none;
  }
  [role="tab"] .icon {
    display: block;
    fill: #009fe3;
    height: 1.5625rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5625rem;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #fff;
  }
  [role="tabpanel"] {
    border-left: 0.25rem solid #e3efeb;
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5em;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  [role="tabpanel"] a:hover {
    text-decoration: underline;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .accordion h2:after {
    display: none;
  }
  .edit_in_cms {
    display: none;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #e3efeb;
    border-top: 0.125rem solid #c3dcd4;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
  }
  .dialog_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 1.5em 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 7100;
  }
  .dialog_wrapper.show {
    visibility: visible;
  }
  .dialog_overlay {
    background: rgba(68, 68, 68, 0.5);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 0;
  }
  .dialog_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.375rem rgba(68, 68, 68, 0.3), 0 0.0625rem 0.25rem rgba(68, 68, 68, 0.2);
    margin: -0.625rem auto 0;
    max-height: 28.125rem;
    max-width: 35em;
    min-width: 18.5rem;
    opacity: 0;
    outline: 0;
    padding: 1.5em;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: calc(100% - 1.5em);
    z-index: 10;
  }
  [role="dialog"].show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] .title {
    padding: 0 1.875em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.75em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #009fe3;
    height: 2.75rem;
    margin-left: auto;
    padding: 0.25em;
    position: absolute;
    right: 0.75em;
    top: 1em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.75rem;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #444;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 0.5;
    padding: 0 0.75em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li ul {
    background: #009bdd;
    margin-left: 0.75em;
    margin-top: 0.75em;
    padding: 0.75em 0.375em;
    position: absolute;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #fff;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.1875em 0.75em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:hover {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    font-weight: 700;
    text-decoration: underline;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #e3efeb;
    -webkit-box-flex: 1;
            flex-grow: 1;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #007bb0;
    border-color: #006996;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
    z-index: 15;
    background: #17baff;
    border-color: #008dca;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0;
    color: #444;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #444;
    height: 1.5rem;
    margin: 0.5em 0;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 700;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #444;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.25rem;
    color: #009fe3;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    background: #e3efeb;
    -webkit-box-flex: 1;
            flex-grow: 1;
    height: 100%;
    margin-top: 1em;
    padding: 1.5em 1.5em 0.75em;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .button_wrapper .button {
    width: 100%;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #c3dcd4;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #c3dcd4;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #444;
    font-family: "Karma", serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #444;
    font-weight: 700;
  }
  .arbeitnehmer_online_text {
    fill: #444;
    font-family: "Karma", serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #444;
  }
  .arbeitnehmer_online_icon {
    fill: #009fe3;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #009fe3;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #009fe3;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #444;
  }
  .arbeitnehmer_online_lines {
    fill: #009fe3;
  }
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #444;
    font-family: "Karma", serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #444;
    font-weight: 700;
  }
  .u_online_text {
    fill: #444;
    font-family: "Karma", serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #444;
  }
  .u_online_warning_icon {
    fill: #444;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #444;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #009fe3;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #009fe3;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #009fe3;
  }
  .u_online_button:hover .u_online_icon {
    fill: #444;
  }
  .u_online_lines {
    fill: #009fe3;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.75em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.875rem;
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.75em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    width: 1.875rem;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.5em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #e3efeb;
    display: table;
    margin: 0 auto 0.75em;
    padding: 1.5em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #009fe3;
    border-left-width: 0.0625rem;
    border-radius: 0.25rem 0 0 0.25rem;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #444;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 2.75rem;
    margin-right: 0.1875rem;
    padding: 0 1.5em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #009fe3;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0.25rem 0.25rem 0;
    content: "";
    display: table-cell;
    height: 2.75rem;
    position: relative;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    vertical-align: middle;
    width: 2.75rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #444;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #444;
    border-radius: 0.25rem 0 0 0.25rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #007bb0;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #007bb0;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #009fe3;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(0, 159, 227, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #009fe3;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #e3efeb;
    border-radius: 0.25rem;
    color: #444;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #e3efeb;
    border-radius: 0.25rem;
    color: #444;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #c3dcd4;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .kanzlei_im_netz {
    margin: 0 0 1.5em;
  }
  .kanzlei_im_netz a:after {
    clear: both;
  }
  .kanzlei_im_netz a:after,
  .kanzlei_im_netz a:before {
    content: "";
    display: table;
  }
  .kanzlei_im_netz .icon {
    background: #e3efeb;
    border-radius: 0.25rem;
    fill: #444;
    float: left;
    height: 3.625rem;
    margin: 0.75em 1.5em 0 0;
    padding: 0.3125rem;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_facebook {
    background: #3664a2;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_twitter {
    background: #1da1f2;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_vimeo {
    background: #247393;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_nls {
    background: #13c6b7;
    fill: #fff;
  }
  .kanzlei_im_netz .icon svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .toggle_offcanvas_menu {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    height: 2.125rem;
    margin-left: 0.375em;
    position: relative;
    width: 2.125rem;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.125rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.125rem;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    border: 0.125rem solid #fff;
    border-radius: 50%;
    height: 100%;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .icon:hover {
    background: #5a2f7c;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.125rem;
    left: 0.375rem;
    margin: 0 auto;
    position: absolute;
    right: 0.375rem;
    text-align: center;
    -webkit-transition: background 300ms;
    transition: background 300ms;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.5rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.875rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.5rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1 {
    top: 0.875rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3 {
    bottom: 0.875rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    z-index: 5000;
    flex-direction: row;
  }
  .offcanvas_menu_wrapper .page {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    min-height: 100vh;
    position: relative;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(68, 68, 68, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #fff;
    -webkit-box-flex: 1;
            flex: 1 0 15.625rem;
    opacity: 0;
    padding-bottom: 1.5em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    background: #009fe3;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    margin-bottom: 0.0625rem;
    padding: 0.75em 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    background: #154356;
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li[aria-expanded="true"] .icon {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li .icon {
    display: block;
    fill: #fff;
    -webkit-box-flex: 0;
            flex: 0 0 1.4375rem;
    height: 1.4375rem;
    line-height: 1.4375rem;
    margin-left: auto;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    background: #e3efeb;
    color: #154356;
    display: block;
    margin-bottom: 0.0625rem;
    padding: 0.75em 2.25em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    background: #154356;
    color: #fff;
    text-decoration: underline;
  }
  .onlinetools_popup_overview .entry {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0 0 1.5em;
    padding: 1.5em;
  }
  .onlinetools_popup_overview h3 {
    padding: 0;
  }
  .onlinetools_popup_overview .button {
    align-self: flex-start;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(68, 68, 68, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    background: #009fe3;
    border-radius: 0.25rem 0.25rem 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(68, 68, 68, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #c3dcd4;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .toggle_search_box {
    height: 3rem;
    position: relative;
    width: 3rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a .icon {
    background: #009fe3;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  .toggle_search_box a .icon {
    border: 0.125rem solid #fff;
    border-radius: 50%;
    display: block;
    fill: #fff;
    height: 2.125rem;
    padding: 0.1875rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(68, 68, 68, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    border: 0.0625rem solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(68, 68, 68, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.3125rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
    background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
    border-color: #fff;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 1.25rem rgba(68, 68, 68, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.375em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0.25rem 0.25rem 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    border: 0;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(2.75rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button .icon {
    fill: #fff;
    height: 1.5rem;
    margin: 0.5em 0;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 700;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #e3efeb;
    border-color: #d3e6df;
    color: #c3c3c3;
  }
  form[role="search"] button[disabled] .icon {
    fill: #fff;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
    position: relative;
    z-index: 10;
    background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
    background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
    border-color: #fff;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #c3dcd4;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: 0 0 0.1875rem #c3dcd4 inset;
    color: #444;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #009fe3;
    box-shadow: 0 0 0.1875rem #009fe3 inset, 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
    color: #444;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  main .main_slogan {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.5em;
  }
  main .main_slogan .slogan_wrapper {
    position: inherit;
  }
  main .main_slogan .slogan_wrapper .slogan {
    position: relative;
  }
  main .main_slogan .slogan_wrapper .slogan_circle {
    background: none;
    box-shadow: none;
    fill: #154356;
    height: 11.625rem;
    width: 11.875rem;
  }
  main .main_slogan .slogan_wrapper .headline {
    color: #154356;
    height: 11.625rem;
    margin-top: 0.125rem;
    width: 11.875rem;
  }
  main .main_slogan .slogan_wrapper .headline .first,
  main .main_slogan .slogan_wrapper .headline .second,
  main .main_slogan .slogan_wrapper .headline .third {
    font-size: 155%;
  }
  footer .container_relative {
    position: relative;
  }
  footer .slogan_wrapper.footer_slogan {
    -webkit-box-align: center;
            align-items: center;
    display: none;
    -webkit-box-flex: 1;
            flex-grow: 1;
    height: 100%;
    padding: 1.5em 0;
    position: absolute;
    right: 0.9375rem;
    top: 0;
    width: 6.875rem;
  }
  footer .slogan_wrapper .slogan {
    position: relative;
  }
  footer .slogan_wrapper .slogan_circle {
    background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0) 100%);
    box-shadow: 0 0 6.875rem 0 rgba(0, 0, 0, 0.25);
    fill: #fff;
    height: 6.75rem;
    width: 6.875rem;
  }
  footer .slogan_wrapper .headline {
    color: #fff;
    height: 6.75rem;
    margin-top: 0.125rem;
    text-shadow: 0 0 0;
    width: 6.875rem;
  }
  footer .slogan_wrapper .headline .first,
  footer .slogan_wrapper .headline .second,
  footer .slogan_wrapper .headline .third {
    font-size: 110%;
  }
  .slogan_wrapper {
    position: absolute;
    right: 5%;
    top: 20%;
    z-index: 50;
  }
  .slogan_wrapper .slogan_circle {
    background: -webkit-radial-gradient(center, ellipse, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.9) 70%, rgba(255, 255, 255, 0.9) 100%);
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.9) 70%, rgba(255, 255, 255, 0.9) 100%);
    border-radius: 50%;
    box-shadow: 0 0 0.75rem 0.5rem rgba(255, 255, 255, 0.8);
    display: block;
    fill: #009fe3;
    height: 7.9375rem;
    opacity: 0;
    -webkit-transition: 1500ms;
    transition: 1500ms;
    width: 8.125rem;
  }
  .slogan_wrapper .slogan_circle.animated {
    opacity: 1;
  }
  .slogan_wrapper .headline {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    color: #009fe3;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    margin-top: 0.9375rem;
    position: absolute;
    right: 0;
    text-shadow: 0 0 0.1875rem #fff;
    top: 0;
  }
  .slogan_wrapper .headline.animated .first,
  .slogan_wrapper .headline.animated .second,
  .slogan_wrapper .headline.animated .third {
    opacity: 1;
    top: 0;
  }
  .slogan_wrapper .headline.animated .small_circle.one,
  .slogan_wrapper .headline.animated .small_circle.two,
  .slogan_wrapper .headline.animated .small_circle.three {
    opacity: 1;
    top: 0;
  }
  .slogan_wrapper .headline .first,
  .slogan_wrapper .headline .second,
  .slogan_wrapper .headline .third {
    font-size: 110%;
    opacity: 0;
    position: relative;
    top: -6.25rem;
  }
  .slogan_wrapper .headline .first {
    -webkit-transition: 300ms 400ms;
    transition: 300ms 400ms;
  }
  .slogan_wrapper .headline .second {
    -webkit-transition: 300ms 800ms;
    transition: 300ms 800ms;
  }
  .slogan_wrapper .headline .third {
    -webkit-transition: 300ms 1200ms;
    transition: 300ms 1200ms;
  }
  .slogan_wrapper .headline .small_circle {
    background: #009fe3;
    border-radius: 50%;
    display: block;
    height: 0.1875rem;
    margin: 0.0625rem 0;
    opacity: 0;
    position: relative;
    top: -0.625rem;
    width: 0.1875rem;
  }
  .slogan_wrapper .headline .small_circle.one {
    -webkit-transition: 300ms 2000ms;
    transition: 300ms 2000ms;
  }
  .slogan_wrapper .headline .small_circle.two {
    -webkit-transition: 300ms 2300ms;
    transition: 300ms 2300ms;
  }
  .slogan_wrapper .headline .small_circle.three {
    -webkit-transition: 300ms 2600ms;
    transition: 300ms 2600ms;
  }
  header .social_media_links {
    display: none;
  }
  footer .social_media_links {
    display: -webkit-box;
    display: flex;
  }
  .header_links {
    display: -webkit-box;
    display: flex;
  }
  .social_media_links ul,
  .header_links ul {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li,
  .header_links li {
    height: 2.125rem;
    list-style: none;
    margin: 0;
    margin-right: 0.5em;
    width: 2.125rem;
  }
  .social_media_links li:hover a .icon,
  .header_links li:hover a .icon {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .social_media_links li:hover a.facebook .icon,
  .header_links li:hover a.facebook .icon {
    background: #3664a2;
  }
  .social_media_links li:hover a.twitter .icon,
  .header_links li:hover a.twitter .icon {
    background: #1da1f2;
  }
  .social_media_links li:hover a.video .icon,
  .header_links li:hover a.video .icon {
    background: #247393;
  }
  .social_media_links li:hover a.email .icon,
  .header_links li:hover a.email .icon {
    background: #13c6b7;
  }
  .social_media_links a,
  .header_links a {
    border-radius: 0.25rem;
    display: inline-block;
  }
  .social_media_links a .icon,
  .header_links a .icon {
    border: 0.125rem solid #fff;
    border-radius: 50%;
    display: block;
    fill: #fff;
    height: 2.125rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.125rem;
  }
  .social_media_links a.facebook .icon,
  .header_links a.facebook .icon {
    padding: 0.15625rem;
  }
  .social_media_links a.facebook .icon svg,
  .header_links a.facebook .icon svg {
    left: -0.1875rem;
    position: relative;
    top: -0.125rem;
  }
  .social_media_links a.twitter .icon,
  .header_links a.twitter .icon {
    padding: 0.125rem;
  }
  .social_media_links a.video .icon,
  .header_links a.video .icon {
    padding: 0.125rem;
  }
  .social_media_links a.email .icon,
  .header_links a.email .icon {
    padding: 0.3125rem;
  }
  .social_media_share {
    padding: 1em 0 2.25em;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #444;
    cursor: pointer;
    display: block;
    margin-right: 0.75em;
    padding-right: 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0.25rem;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 700;
    padding-left: 0.75em;
  }
  .social_media_share a.facebook .icon {
    background: #3664a2;
  }
  .social_media_share a.twitter .icon {
    background: #1da1f2;
  }
  .social_media_share a.print .icon {
    background: #009fe3;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_legal_notice {
    padding: 1.5em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    text-align: center;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    margin: 0 0.75em 0.75em 0;
    padding: 0.75em 1.5em;
  }
  .steuernews_content [href*="thema"] {
    color: #444;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 6.25rem;
    margin-bottom: 1em;
    overflow: hidden;
    position: relative;
  }
  .steuernews_preview .entry:hover .teaser_text {
    background: rgba(255, 255, 255, 0.8);
    color: #444;
  }
  .steuernews_preview .entry:hover h3 {
    color: #444;
  }
  .steuernews_preview .entry h3 {
    letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0;
    max-width: 100%;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
  }
  .steuernews_preview .teaser_text {
    background: rgba(21, 67, 86, 0.8);
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.75em;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 100%;
    z-index: 10;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #009fe3;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview small,
  .steuernews_more_articles_overview small {
    display: block;
    font-size: 60%;
  }
  .steuernews_overview {
    margin: 2.25em 0;
  }
  .steuernews_overview .entry_wrapper {
    margin-bottom: 3em;
    min-height: 0.0625rem;
    position: relative;
  }
  .steuernews_overview .entry_wrapper .arrow_button {
    bottom: -7%;
    position: absolute;
    right: 15%;
    z-index: 100;
  }
  .steuernews_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    max-width: 43.75rem;
    position: relative;
  }
  .steuernews_overview .entry:hover .text_part {
    background: rgba(255, 255, 255, 0.8);
    color: #444;
  }
  .steuernews_overview .entry:hover h3 {
    color: #444;
  }
  .steuernews_overview .entry h3 {
    letter-spacing: calc((1.1875rem + 0.3125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
  }
  .steuernews_overview .text_part {
    background: rgba(21, 67, 86, 0.8);
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.5em;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 100%;
    z-index: 10;
  }
  .steuernews_overview .image {
    line-height: 0;
    max-height: 15.625rem;
    overflow: hidden;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .entry:hover .icon {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .steuernews_video_overview .image {
    margin-bottom: 1.5em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #009fe3;
    height: 3.4375rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .steuernews_video_overview figcaption {
    background: #009fe3;
    color: #fff;
    margin-top: 0;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
  }
  .steuernews_contact_person {
    background: #e3efeb;
    border-radius: 0.25rem;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0.25rem;
    color: #009fe3;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #e3efeb;
    border-radius: 0.25rem;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_more_articles_overview {
    margin-top: 1.5em;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_issues {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding: 1.5em 0;
    position: relative;
    z-index: 1;
  }
  .steuernews_issues:after {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    content: "";
    height: 100%;
    left: -10%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  .steuernews_current_issue {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    color: #fff;
    -webkit-box-flex: 1;
            flex-grow: 1;
    padding: 1.5em 0;
    position: relative;
    z-index: 1;
  }
  .steuernews_current_issue:before {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    content: "";
    height: 100%;
    position: absolute;
    right: -10%;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  .steuernews_current_issue a,
  .steuernews_current_issue h2 {
    color: #fff;
  }
  .steuernews_current_issue a {
    text-decoration: none;
  }
  .steuernews_current_issue a:hover,
  .steuernews_current_issue a.active {
    text-decoration: underline;
  }
  .steuernews_current_issue ul {
    list-style: url("../images/list_style_image_circle_light.svg");
  }
  .keyword_register {
    margin-bottom: 0.75em;
  }
  .keyword_register:after {
    clear: both;
  }
  .keyword_register:after,
  .keyword_register:before {
    content: "";
    display: table;
  }
  .keyword_register a {
    background: #e3efeb;
    float: left;
    line-height: 2.75rem;
    margin: 0 0.75em 0.75em 0;
    text-align: center;
    width: 2.75rem;
  }
  .steuernews_keywords ul,
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_keywords a,
  .steuernews_issues a {
    display: inline-block;
    line-height: 2.75rem;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    white-space: nowrap;
  }
  .steuernews_keywords {
    margin-bottom: 1.5em;
  }
  .steuernews_keywords_register .keywords_register_entries a {
    display: inline-block;
    max-width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .steuernews_call_to_action {
    background: #e3efeb;
    border-radius: 0.25rem;
    display: inline-block;
    margin: 0 0 1.5em;
    padding: 0.75em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .highlight_keyword {
    background: #009fe3;
    color: #fff;
    outline: 0.125rem solid #009fe3;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .team_overview .type {
    margin-right: 0.375em;
  }
  .team_overview .title {
    padding: 0;
  }
  .team_overview h2 {
    margin: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 1.5em 0;
    max-width: 20.1875rem;
    position: relative;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    padding-top: 1.5em;
    position: relative;
    z-index: 1;
  }
  .team_overview .info:before {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    content: "";
    height: 0.1875rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .team_overview .info .type {
    min-width: 6.25rem;
  }
  .team_overview .accordion .tab_title {
    letter-spacing: calc((0.875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    margin-left: 0.75em;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .team_overview .accordion [role="tab"] {
    background: inherit;
    padding: 0;
  }
  .team_overview .accordion [role="tab"]:hover .icon,
  .team_overview .accordion [role="tab"]:focus .icon {
    fill: #009fe3;
  }
  .team_overview .accordion [role="tab"]:hover .tab_title,
  .team_overview .accordion [role="tab"]:focus .tab_title {
    color: #009fe3;
  }
  .team_overview .accordion [role="tab"] .icon {
    fill: #444;
  }
  .team_overview .accordion [role="tabpanel"] {
    border-left: inherit;
    margin-top: 0.75em;
  }
  .team_overview [role="tablist"] {
    margin: 0 0 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 20.1875rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .team_group_overview .entry {
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .startpage .teaser_overview {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .startpage .teaser_overview .teaser {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
    z-index: 1;
  }
  .startpage .teaser_overview .teaser.left {
    padding: 2.25em 1.5em 2.25em 0;
  }
  .startpage .teaser_overview .teaser.right {
    padding: 2.25em 1.5em 2.25em 0;
  }
  .startpage .teaser_overview .teaser.right:before {
    left: -10%;
    right: inherit;
  }
  .startpage .teaser_overview .teaser.blue_background {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    color: #fff;
    z-index: 2;
  }
  .startpage .teaser_overview .teaser.blue_background:before {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
  }
  .startpage .teaser_overview .teaser.blue_background h2 {
    color: #fff;
  }
  .startpage .teaser_overview .teaser:before {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    content: "";
    height: 100%;
    position: absolute;
    right: -10%;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  .startpage .teaser_overview .teaser_inner {
    position: relative;
    z-index: 500;
  }
  .startpage .teaser_overview h2 {
    max-width: 38.125rem;
    padding: 0;
  }
  .startpage .teaser_overview strong {
    color: #009fe3;
  }
  .startpage .teaser_overview .font_weight_normal h2 {
    font-weight: 400;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #e3efeb;
    border: 0.0625rem solid #c3dcd4;
    border-radius: 0.25rem;
    color: #444;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #e3efeb;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #c3dcd4;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry {
    display: inline-block;
  }
  .video_overview .image,
  .video_infolists_overview .image {
    margin: 0.75em 0;
    max-width: 21.5rem;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon {
    fill: #009fe3;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_infolists_overview a.entry,
  .video_overview a.entry,
  .video_preview a.entry {
    text-decoration: none;
    width: 100%;
  }
  .video_infolists_overview a.entry:hover .icon,
  .video_overview a.entry:hover .icon,
  .video_preview a.entry:hover .icon {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .video_infolists_overview a.entry .icon,
  .video_overview a.entry .icon,
  .video_preview a.entry .icon {
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .video_infolists_overview a.entry .video_play,
  .video_overview a.entry .video_play,
  .video_preview a.entry .video_play {
    line-height: 0;
  }
  .video_infolists_overview a.entry .description,
  .video_overview a.entry .description,
  .video_preview a.entry .description {
    background: #154356;
    color: #fff;
    margin-top: 0;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
  }
  .more_steuernews_tv .entry {
    max-width: 31.25rem;
    width: 100%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  a.button.video_channel {
    background: #247393;
    border-color: #103342;
    color: #fff;
  }
  a.button.video_channel .icon {
    fill: #fff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
    max-width: 20rem;
  }
  .video_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0 0.75em;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #009fe3;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .open_street_map .map {
    background-color: #e3efeb;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(227, 239, 235, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
    border: 0.0625rem solid #fff;
    border-radius: 0.25rem;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
    background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
    border: 0.0625rem solid #fff;
    box-shadow: 0 0 0 0.1875rem rgba(0, 159, 227, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: -webkit-linear-gradient(top, #0082b9 100%, #009bdd 0%);
    background: linear-gradient(to bottom, #0082b9 100%, #009bdd 0%);
    border: 0.0625rem solid #fff;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #e3efeb;
    border-radius: 0.25rem;
    color: #444;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  body.layout_south .header_picture.header_picture_north,
  body.layout_south_page .header_picture.header_picture_north {
    display: none !important;
  }
  body.layout_south .header_picture.header_picture_south,
  body.layout_south_page .header_picture.header_picture_south {
    display: block !important;
  }
  body.layout_south .header_bottom .layout_north,
  body.layout_south_page .header_bottom .layout_north {
    display: none !important;
  }
  body.layout_south .header_bottom .layout_south,
  body.layout_south_page .header_bottom .layout_south {
    display: block !important;
  }
  body.layout_south .team_north_south .row .span_column,
  body.layout_south_page .team_north_south .row .span_column {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  body.layout_south .team_north_south .row .span_column.south_first,
  body.layout_south_page .team_north_south .row .span_column.south_first {
    -webkit-box-ordinal-group: 1 !important;
            order: 0 !important;
  }
  body.layout_south main .layout_north,
  body.layout_south_page main .layout_north {
    display: none !important;
  }
  body.layout_south main .layout_south,
  body.layout_south_page main .layout_south {
    display: block !important;
  }
  body.layout_south footer,
  body.layout_south_page footer {
    /*
            .blue_background {
                @media (min-width: @large_grid_breakpoint) {
                    padding-left: 0;
                }
                
                &:after {                    
                    background: @main_color;
                    content: "";
                    height: 100%;
                    left: -10%;
                    position: absolute;
                    top: 0;
                    width: 1000%;
                    z-index: -1;

                    @media (min-width: @large_grid_breakpoint) {
                        left: -50%;
                        width: 100%;
                    }
                }
            }*/
  }
  body.layout_south footer .layout_north,
  body.layout_south_page footer .layout_north {
    display: none !important;
  }
  body.layout_south footer .layout_south,
  body.layout_south_page footer .layout_south {
    display: block !important;
    /*.row {
                    flex-direction: column-reverse; 
                    
                    @media (min-width: @large_grid_breakpoint) {
                        flex-direction: row-reverse;
                    }
                }*/
  }
  body.layout_south footer .addess_big,
  body.layout_south_page footer .addess_big {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
  }
  body.layout_south footer .addess_big .grey_background:first-child,
  body.layout_south_page footer .addess_big .grey_background:first-child {
    padding-top: 0;
  }
  body.layout_south footer .grey_background .grey_background_child,
  body.layout_south_page footer .grey_background .grey_background_child {
    padding-bottom: 1.07142857em;
    padding-left: 0;
  }
  body.layout_south footer .grey_background.grey_background h2,
  body.layout_south_page footer .grey_background.grey_background h2 {
    margin-bottom: 0;
  }
  body.layout_south footer .grey_background:before,
  body.layout_south_page footer .grey_background:before {
    background: #e3efeb;
    content: "";
    height: 100%;
    position: absolute;
    right: -10%;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  body.layout_south footer .red_background:after,
  body.layout_south_page footer .red_background:after {
    background: #800032;
    content: "";
    height: 100%;
    left: -10%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
  body.layout_north .header_picture.header_picture_north {
    display: block;
  }
  body.layout_north .header_picture.header_picture_south {
    display: none;
  }
  body.layout_north .header_bottom .layout_north {
    display: block;
  }
  body.layout_north .header_bottom .layout_south {
    display: none;
  }
  body.layout_north main .layout_north {
    display: block !important;
  }
  body.layout_north main .layout_south {
    display: none !important;
  }
  body.layout_north footer .layout_north {
    display: block;
  }
  body.layout_north footer .layout_south {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30em) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5em) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875em) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75em) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75em) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30em) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75em) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75em) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .header_top {
    padding: 1.5em 0;
  }
}
@media screen and (min-width: 30em) {
  .header_top .flex_container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
            align-items: flex-end;
  }
}
@media screen and (min-width: 30em) {
  .header_top .flex_container .company_logo {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 35em) {
  .header_layout_select {
    margin-right: 0.625em;
  }
}
@media screen and (min-width: 30em) {
  .header_bottom .seo_start_title {
    bottom: 30%;
  }
}
@media screen and (max-width: 47.4375em) {
  .header_bottom .seo_start_title {
    left: 0;
    padding-left: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
    width: 100%;
  }
}
@media screen and (min-width: 47.5em) {
  .header_bottom .seo_start_title {
    bottom: 6em;
  }
}
@media screen and (max-width: 47.4375em) and (min-width: 35em) {
  .header_bottom .seo_start_title h1 {
    max-width: 19.375rem;
  }
}
@media screen and (min-width: 30em) {
  .header_bottom .seo_start_title h1 .small {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .header_bottom .logo_balken {
    border-bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1em;
  }
}
@media screen and (min-width: 47.5em) {
  .header_bottom .logo_balken .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 75em) {
  .header_bottom .logo_balken .row {
    justify-content: space-around;
  }
}
@media screen and (min-width: 47.5em) {
  .header_bottom .logo_balken .row div {
    margin: 0 0.5rem;
  }
}
@media screen and (max-width: 47.4375em) {
  .header_bottom .logo_balken .logo_1,
  .header_bottom .logo_balken .logo_2,
  .header_bottom .logo_balken .logo_3,
  .header_bottom .logo_balken .logo_4 {
    margin: 0.25rem auto;
    max-width: 13.75rem;
  }
}
@media screen and (min-width: 47.5em) {
  .header_bottom .logo_balken .logo_2 {
    margin: 0 auto;
  }
  .header_bottom .logo_balken .logo_3 {
    margin-left: auto;
  }
}
@media screen and (min-width: 47.5em) {
  .author_ver {
    left: 0.4375rem;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .main_wrapper_top .bogen,
  .startpage .main_wrapper_bottom .bogen {
    bottom: 0;
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 10;
  }
}
@media screen and (min-width: 61.875em) {
  main .bogen_unterseite {
    background-image: url("../images/bogen_unterseite.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    display: block;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-height: 43.75rem;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    right: 50%;
    top: 0;
    width: 100vw;
    z-index: 10;
  }
}
@media screen and (min-width: 75em) {
  main .bogen_unterseite {
    background-position: top left;
  }
}
@media screen and (min-width: 47.5em) {
  .arrow_button .text {
    margin-right: 3em;
  }
}
@media screen and (min-width: 47.5em) {
  .announcement {
    padding: 4.5em 0 4.5em 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  .links_overview {
    padding: 4.5em 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  .links_overview:after {
    left: 50%;
  }
}
@media screen and (min-width: 47.5em) {
  .links_overview .title {
    display: none;
  }
}
@media screen and (max-width: 41.8125rem) and (min-width: 30em) {
  .links_overview ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .links_overview ul li {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 47.4375em) and (min-width: 41.875rem) {
  .links_overview ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .links_overview ul li {
    flex-basis: 33.33%;
  }
}
@media screen and (min-width: 47.5em) {
  .leistungen_overview {
    padding: 4.5em 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  .leistungen_overview h2 {
    display: block;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .newsletter_teaser .content {
    left: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .startseite_teaser .content {
    left: 50%;
    width: 50%;
  }
}
@media screen and (min-width: 30em) {
  .startpage .startseite_teaser .startseite_teaser_picture {
    min-height: 40.625rem;
  }
}
@media screen and (min-width: 20rem) {
  .text_center p {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .text_center p {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 61.875em) {
  .text_center p {
    width: 60%;
  }
}
@media screen and (min-width: 75em) {
  .text_center p {
    width: 50%;
  }
}
@media screen and (max-width: 93.75em) {
  .bester_stb {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .bester_stb_2020 {
    bottom: 30%;
    left: 4%;
  }
}
@media screen and (min-width: 61.875em) {
  .bester_stb_2020 {
    bottom: 48%;
    left: 4%;
  }
}
@media screen and (min-width: 75em) {
  .bester_stb_2020 {
    bottom: 38%;
    left: 4%;
  }
}
@media screen and (min-width: 93.75em) {
  .bester_stb_2020 {
    bottom: 33%;
    left: 3.5%;
  }
}
@media screen and (min-width: 30em) {
  .bester_stb_2020 img {
    max-width: 10rem;
  }
}
@media screen and (min-width: 35em) {
  .bester_stb_2020 img {
    max-width: 12rem;
  }
}
@media screen and (min-width: 47.5em) {
  .bester_stb_2020 img {
    max-width: 14rem;
  }
}
@media screen and (min-width: 61.875em) {
  .bester_stb_2020 img {
    max-width: 6rem;
  }
}
@media screen and (min-width: 75em) {
  .bester_stb_2020 img {
    max-width: 12rem;
  }
}
@media screen and (min-width: 93.75em) {
  .bester_stb_2020 img {
    max-width: 18rem;
  }
}
@media screen and (min-width: 61.875em) {
  .aside {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .aside .newsletter_teaser h2,
  .aside .teaser_overview h2 {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .aside .newsletter_teaser h2,
  .aside .teaser_overview h2 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 93.75em) {
  .aside .arrow_button {
    margin-left: -45%;
    width: 130%;
  }
}
@media screen and (min-width: 75em) {
  .aside .sidebar {
    width: 75%;
  }
}
@media screen and (min-width: 47.5em) {
  footer .grey_background:before {
    right: 50%;
  }
}
@media screen and (min-width: 47.5em) {
  footer .blue_background {
    padding-left: 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  footer .blue_background:after {
    left: 50%;
  }
}
@media screen and (min-width: 47.5em) {
  footer .red_background {
    padding-left: 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  footer .red_background:after {
    left: 50%;
  }
}
@media screen and (min-width: 20rem) {
  footer .lower_part .text {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  footer .lower_part .text {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .link_entries {
    left: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .link_entries {
    left: 3.125rem;
  }
}
@media screen and (min-width: 61.875em) {
  .edit_in_cms {
    bottom: 1.25rem;
    display: none;
    background: #009fe3;
    border-radius: 0.25rem;
    color: #fff;
    padding: 0.75em 1.5em;
    position: fixed;
    right: 1.25rem;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    z-index: 9999;
  }
  .edit_in_cms:hover {
    background: #444;
    color: #fff;
  }
}
@media screen and (min-width: 93.75em) {
  .dropdown_menu {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 93.75em) {
  .google_maps .direction {
    margin: 0;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 61.875em) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.75em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    display: block;
    height: 0;
    margin: 0 auto;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    width: auto;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.75em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    content: "";
    display: block;
    height: 0;
    margin: 0 auto 0.8em;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: auto;
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.5em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 93.75em) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 75em) {
  .offcanvas_menu_wrapper {
    overflow-x: inherit;
  }
}
@media screen and (max-width: 93.6875em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: translate(15.625rem, 0);
            transform: translate(15.625rem, 0);
    visibility: visible;
    -webkit-transform: translate(-15.625rem, 0);
            transform: translate(-15.625rem, 0);
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 93.6875em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(21.875rem, 0);
            transform: translate(21.875rem, 0);
  }
}
@media screen and (max-width: 93.6875em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(-21.875rem, 0);
            transform: translate(-21.875rem, 0);
  }
}
@media screen and (min-width: 30em) {
  .offcanvas_menu {
    -webkit-box-flex: 1;
            flex: 1 0 21.875rem;
  }
}
@media screen and (min-width: 61.875em) {
  main .main_slogan .slogan_wrapper .slogan_circle {
    height: 14.0625rem;
    width: 14.375rem;
  }
}
@media screen and (min-width: 61.875em) {
  main .main_slogan .slogan_wrapper .headline {
    height: 14.0625rem;
    width: 14.375rem;
  }
}
@media screen and (min-width: 61.875em) {
  main .main_slogan .slogan_wrapper .headline .first,
  main .main_slogan .slogan_wrapper .headline .second,
  main .main_slogan .slogan_wrapper .headline .third {
    font-size: 185%;
  }
}
@media screen and (min-width: 20rem) {
  footer .slogan_wrapper.footer_slogan {
    right: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 30em) {
  footer .slogan_wrapper.footer_slogan {
    width: 10.0625rem;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 93.75em) {
  footer .slogan_wrapper.footer_slogan {
    right: 3.125rem;
  }
}
@media screen and (min-width: 30em) {
  footer .slogan_wrapper .slogan_circle {
    height: 8.5625rem;
    width: 8.75rem;
  }
}
@media screen and (min-width: 30em) {
  footer .slogan_wrapper .headline {
    height: 8.5625rem;
    width: 8.75rem;
  }
}
@media screen and (min-width: 30em) {
  footer .slogan_wrapper .headline .first,
  footer .slogan_wrapper .headline .second,
  footer .slogan_wrapper .headline .third {
    font-size: 110%;
  }
}
@media screen and (min-width: 35em) {
  .slogan_wrapper {
    top: 25%;
  }
}
@media screen and (min-width: 47.5em) {
  .slogan_wrapper {
    bottom: 32%;
    top: inherit;
  }
}
@media screen and (min-width: 61.875em) {
  .slogan_wrapper {
    bottom: 26%;
    right: 12%;
  }
}
@media screen and (min-width: 75em) {
  .slogan_wrapper {
    right: 20%;
  }
}
@media screen and (min-width: 80rem) {
  .slogan_wrapper .slogan_circle {
    height: 9.1875rem;
    width: 9.375rem;
  }
}
@media screen and (min-width: 93.75em) {
  .slogan_wrapper .slogan_circle {
    height: 14.0625rem;
    width: 14.375rem;
  }
}
@media screen and (min-width: 38.75rem) {
  .slogan_wrapper .headline {
    margin-top: 1.5625rem;
  }
}
@media screen and (min-width: 80rem) {
  .slogan_wrapper .headline .first,
  .slogan_wrapper .headline .second,
  .slogan_wrapper .headline .third {
    font-size: 130%;
  }
}
@media screen and (min-width: 93.75em) {
  .slogan_wrapper .headline .first,
  .slogan_wrapper .headline .second,
  .slogan_wrapper .headline .third {
    font-size: 185%;
  }
}
@media screen and (min-width: 47.5em) {
  .slogan_wrapper .headline .small_circle {
    height: 0.25rem;
    width: 0.25rem;
  }
}
@media screen and (min-width: 75em) {
  .slogan_wrapper .headline .small_circle {
    height: 0.375rem;
    margin: 0.25rem 0;
    width: 0.375rem;
  }
}
@media screen and (min-width: 35em) {
  header .social_media_links {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35em) {
  footer .social_media_links {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .steuernews_preview .entry {
    height: inherit;
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry h3 {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .steuernews_preview .entry h3 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_overview {
    margin: 4.5em 0;
  }
}
@media screen and (min-width: 30em) {
  .steuernews_overview .entry_wrapper .arrow_button {
    right: 6%;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_overview .entry_wrapper .arrow_button {
    right: 3%;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_overview .entry_wrapper .arrow_button .text {
    margin-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .steuernews_overview .entry_wrapper .arrow_button .text {
    margin-right: 1.5em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_overview .entry h3 {
    font-size: calc(1.1875rem + 0.3125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .steuernews_overview .entry h3 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 47.5em) {
  .steuernews_overview .image {
    max-height: inherit;
  }
}
@media screen and (min-width: 35em) {
  .news_image {
    float: right;
    margin-left: 1.5em;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_issues {
    padding: 1.5em 2.25em;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_issues:after {
    display: none;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_current_issue {
    padding: 1.5em 2.25em;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_current_issue:before {
    display: none;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .info {
    width: 80%;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .accordion .tab_title {
    font-size: calc(0.875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .accordion .tab_title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .teaser_overview .teaser.padding_bottom {
    padding: 4.5em 2.25em 9em 0;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .teaser_overview .teaser.padding_top {
    padding: 9em 2.25em 4.5em;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .teaser_overview .teaser.left {
    padding: 4.5em 2.25em 4.5em 0;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage .teaser_overview .teaser.right {
    padding: 4.5em 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .teaser_overview .teaser.right:before {
    left: 50%;
  }
}
@media screen and (max-width: 61.8125em) {
  .startpage .teaser_overview .teaser.right:after {
    right: 100%;
  }
}
@media screen and (max-width: 61.8125em) {
  .startpage .teaser_overview .teaser.blue_background:after {
    background: -webkit-linear-gradient(top, #009bdd 0%, #0082b9 100%);
    background: linear-gradient(to bottom, #009bdd 0%, #0082b9 100%);
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .teaser_overview .teaser:before {
    right: 50%;
  }
}
@media screen and (max-width: 61.8125em) {
  .startpage .teaser_overview .teaser:after {
    background: -webkit-linear-gradient(top, #f5f9f7 0%, #e4eeeb 100%);
    background: linear-gradient(to bottom, #f5f9f7 0%, #e4eeeb 100%);
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: -1;
  }
}
@media screen and (max-width: 61.8125em) and (min-width: 61.875em) {
  .startpage .teaser_overview .teaser:after {
    left: 100%;
  }
}
@media screen and (min-width: 47.5em) {
  .video_preview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    max-width: inherit;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5em) {
  body.layout_south footer .grey_background,
  body.layout_south_page footer .grey_background {
    padding-left: 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  body.layout_south footer .grey_background .grey_background_child,
  body.layout_south_page footer .grey_background .grey_background_child {
    padding-left: 2.25em;
  }
}
@media screen and (min-width: 47.5em) {
  body.layout_south footer .grey_background:before,
  body.layout_south_page footer .grey_background:before {
    right: -50%;
    width: 100%;
  }
}
@media screen and (min-width: 47.5em) {
  body.layout_south footer .red_background,
  body.layout_south_page footer .red_background {
    padding-left: 0;
  }
}
@media screen and (min-width: 47.5em) {
  body.layout_south footer .red_background:after,
  body.layout_south_page footer .red_background:after {
    left: -50%;
    width: 100%;
  }
}
@media print {
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .company_logo {
    display: block;
    width: 12.5rem;
    margin-bottom: 1.5em;
  }
  .company_logo svg {
    fill: #000000;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
